.admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.qr-reader {
  margin-bottom: 2rem;
}

.attendance-table {
  width: 100%;
}

.attendance-table table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-table th,
.attendance-table td {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-align: left;
}

.attendance-table th {
  background-color: #f2f2f2;
}

.complete-text {
  color: red;
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  font-weight: 'bold';
  font-size: '24px';
}