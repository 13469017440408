.logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background-color: white;
  /* padding: 10px; */
  z-index: 1000;
  margin-top: 55px;
}

.logo-img {
  max-width: 90%;
  width: auto;
  height: auto;
}

@media (min-width: 600px) {
  .logo {
    margin-top: 75px;
  }

  .logo-img {
    max-width: 60%;
    width: auto;
    height: auto;
  }
}